import * as React from "react";
import { Link } from "../../UI/Link/Link";
import { FormField } from "../../Form/FormField/FormField";
import { Input } from "../../Form/Input/Input";
import { Form } from "../../Form/Form";
import { Icon } from "../../UI/IconComponent/Icon";
import { IconButton } from "../../UI/IconButton/IconButton";
import { cx } from "emotion";
import { Select } from "../../Form/Select/Select";
import { DiscoverSavedSearchButton } from "./DiscoverSavedSearchButton/DiscoverSavedSearchButton";
import NewSavedSearchModal from "../../../modals/NewSavedSearchModal";
import { TextareaHighlightable } from "../../Form/TextareaHighlightable/TextareaHighlightable";
import { Formatter } from "../../util/Formatter";

import css from "./DiscoverFilters.module.css";

import {
  AUTOCOMPLETE_OFF,
  COMPANY_NAME_OPTIONS,
  COMPANY_SIZE_OPTIONS,
  DISCOVER_DEGREE_OPTIONS,
  DISCOVER_SIDEBAR_SIZE,
  EDUCATION_MAJOR_OPTIONS,
  INDEX_OPTIONS,
  INDUSTRY_OPTIONS,
  SKILLS_MODE_OPTIONS,
  TITLE_MODE_OPTIONS,
} from "../../../../constants/constants";
import { DiscoverSearchLocationHelper } from "../../helpers/location/DiscoverSearchLocationHelper";
import { Checkbox } from "../../Form/Checkbox/Chekbox";
import { InputMode } from "../../Form/InputMode/InputMode";
import { InputLocationArray } from "../../Form/InputLocationArray/InputLocationArray";
import { ButtonHTML } from "../../UI/ButtonHTML/ButtonHTML";
import { ModalEdit } from "./ModalEdit";
import { FreeSelect } from "components/DiscoverNew/Form/Select/FreeSelect";
import { Button } from "../../UI/Button/Button";
import { ModalAdvancedFilters } from "./ModalAdvancedFilters/ModalAdvancedFilters";
import { UserSettingName } from "../../../../services/UserSettingsService";
import { UserSettingsGuard } from "../../../../hooks/useUserSettings";

export class DiscoverFilters extends React.PureComponent {
  state = {
    showSaveSearchModal: false,
    showEditTitleModal: false,
    showEditSkillsModal: false,
    showEditCompanyNameModal: false,
  };

  onSaveEditTitle = (value) => {
    this.props.onChange({ title: value.title });
    this.hideEditTitleModal();
  };

  onSaveEditSkills = (value) => {
    this.props.onChange({ skills: value.skills });
    this.hideEditSkillsModal();
  };

  onSaveEditSkillsShould = (value) => {
    this.props.onChange({ skillsShould: value.skillsShould });
    this.hideEditSkillsShouldModal();
  };

  onSaveEditCompanyName = (value) => {
    this.props.onChange({ employer: value.employer });
    this.hideEditCompanyNameModal();
  };

  onSaveEditSchool = (value) => {
    this.props.onChange({ school: value.school });
    this.hideEditSchoolModal();
  };

  // TODO: universal modal

  showEditTitleModal = () => {
    this.setState({ showEditTitleModal: true });
  };

  hideEditTitleModal = () => {
    this.setState({ showEditTitleModal: false });
  };

  showEditSkillsModal = () => {
    this.setState({ showEditSkillsModal: true });
  };

  showEditCompanyNameModal = () => {
    this.setState({ showEditCompanyNameModal: true });
  };

  hideEditCompanyNameModal = () => {
    this.setState({ showEditCompanyNameModal: false });
  };

  showEditSchoolModal = () => {
    this.setState({ showEditSchoolModal: true });
  };

  hideEditSchoolModal = () => {
    this.setState({ showEditSchoolModal: false });
  };

  hideEditSkillsModal = () => {
    this.setState({ showEditSkillsModal: false });
  };

  showEditSkillsShouldModal = () => {
    this.setState({ showEditSkillsShouldModal: true });
  };

  hideEditSkillsShouldModal = () => {
    this.setState({ showEditSkillsShouldModal: false });
  };

  showSaveSearchModal = () => {
    this.setState({ showSaveSearchModal: true });
  };

  hideSaveSearchModal = () => {
    this.setState({ showSaveSearchModal: false });
  };

  showAdvancedFilterModal = () => {
    this.setState({ showAdvancedFilterModal: true });
  };

  hideAdvancedFilterModal = () => {
    this.setState({ showAdvancedFilterModal: false });
  };

  onApplyAdvancedFilter = (value) => {
    this.props.onChange({ advanced: value });
    this.hideAdvancedFilterModal();
  }

  render() {
    const { showSaveSearchModal, showEditSkillsModal, showEditSkillsShouldModal, showEditTitleModal, showEditCompanyNameModal, showEditSchoolModal, showAdvancedFilterModal } = this.state;
    const {
      onChange,
      value,
      result,
      sidebarSize,
      onChangeSidebarSize,
      onReset,
      onSave,
      savedSearches,
      recentSavedSearches,
      onDeleteSavedSearch,
      onRestoreSavedSearch,
    } = this.props;

    const {
      title,
      titleMode,
      employer,
      companyNameMode,
      minYears,
      maxYears,
      firstName,
      lastName,
      school,
      degree,
      major,
      addresses,
      country,
      withPhonesOnly,
      withEmailsOnly,
      industries,
      customIndex,
      excludeViewed,
      currentCompanySize,
      skillsMode,
      skills,
      skillsShould,
      skillsMustSelect,
      skillsShouldSelect,
      advanced,
    } = value;

    const renderResultsInfo = () => {
      if (!result || !result.pageInfo) {
        return <span>Search</span>;
      }

      if (!result.pageInfo.total) {
        return (
          <>
            <span>Search</span>
            <Link onClick={onReset} leftIcon={<Icon name="cross" />} color="gray">
              Reset
            </Link>
          </>
        );
      }
      // TODO: add some explanation for the user (e.g. "Showing 10 000 of 593993939 results")
      return (
        <>
          {result?.pageInfo?.total < result?.pageInfo?.count ? (
            <div>
              <p>
                <span>Showing:</span> {result.pageInfo.total.toLocaleString()} results
              </p>
              <p>
                <span>Total:</span> {result.pageInfo.count === 1_000_000 ? " > " : ""}
                {result.pageInfo.count.toLocaleString()} results
              </p>
            </div>
          ) : (
            <p>
              <span>Showing:</span> {result?.pageInfo?.total.toLocaleString()} results
            </p>
          )}
          <Link
            onClick={this.showSaveSearchModal}
            leftIcon={<Icon name="plus" />}
            style={{
              marginLeft: 0,
            }}
          >
            Save
          </Link>
          <Link onClick={onReset} leftIcon={<Icon name="cross" />} color="gray">
            Reset
          </Link>
        </>
      );
    };

    return (
      <div className={cx(css.holder, { [css.expanded]: sidebarSize === DISCOVER_SIDEBAR_SIZE.expanded })}
        style={this.props.userSettings[UserSettingName.luceneSearch] ? { opacity: '0.6', pointerEvents: 'none' } : {}}
      >
        <header className={css.header}>
          <h1 className={css.title}>Discover</h1>
          <DiscoverSavedSearchButton
            savedSearches={savedSearches}
            recentSavedSearches={recentSavedSearches}
            onDeleteSavedSearch={onDeleteSavedSearch}
            onRestoreSavedSearch={onRestoreSavedSearch}
          />
        </header>
        <main className={css.filtersHolder}>
          {!!window.devmode && !!window.lastSearchQuery && (
            <details
              style={{
                backgroundColor: "#f5f5f5",
                padding: 20,
                borderRadius: 5,
              }}
            >
              <summary>Elastic Query</summary>
              <pre
                style={{
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                  maxHeight: 200,
                  overflow: "auto",
                }}
                dangerouslySetInnerHTML={{
                  __html: Formatter.highlightJson(JSON.stringify(window.lastSearchQuery, null, 4)),
                }}
              />
            </details>
          )}
          <div className={css.resultsHolder}>
            <div className={css.resultsInfo}>{renderResultsInfo()}</div>
            <IconButton
              className={css.expandButton}
              onClick={() =>
                onChangeSidebarSize(
                  sidebarSize === DISCOVER_SIDEBAR_SIZE.default
                    ? DISCOVER_SIDEBAR_SIZE.expanded
                    : DISCOVER_SIDEBAR_SIZE.default
                )
              }
            >
              <Icon name="chevron-double" />
            </IconButton>
          </div>
          {!!window.devmode && !window.indextest && (
            <div className={css.filtersGroup}>
              <Form.Row>
                <FormField label="Custom Index">
                  <TextareaHighlightable
                    autoComplete={AUTOCOMPLETE_OFF}
                    value={customIndex}
                    onChange={(e) => onChange({ customIndex: e.target.value })}
                  />
                </FormField>
              </Form.Row>
            </div>
          )}
          <div className={css.divider} />
          {!!window.indextest && (
            <>
              <div className={css.filtersGroup}>
                <details open>
                  <summary style={{ position: "relative" }}>
                    <Icon className={css.detailsArrow} name="fa-chevron" />
                    Index Testing
                  </summary>
                  <div className={css.formDetails}>
                    <Form.Row>
                      <FormField label="Select Index">
                        <Select
                          options={window.index_options || INDEX_OPTIONS}
                          onChange={(index) => onChange({ customIndex: index.value })}
                          value={(window.index_options || INDEX_OPTIONS).filter((item) => customIndex === item.value)}
                          placeholder={"Select..."}
                        />
                      </FormField>
                    </Form.Row>
                  </div>
                </details>
              </div>
              <div className={css.divider} />
            </>
          )}
          <div className={css.filtersGroup}>
            <details open>
              <summary style={{ position: "relative" }}>
                <Icon className={css.detailsArrow} name="fa-chevron" />
                Filters
              </summary>
              <div className={css.formDetails}>
                <Form.Row>
                  <div style={{ display: "flex" }}>
                    <Checkbox checked={excludeViewed} onClick={() => onChange({ excludeViewed: !excludeViewed })} />
                    <FormField.Label style={{ marginLeft: 10 }}>Exclude Viewed</FormField.Label>
                  </div>
                </Form.Row>
                <Form.Row>
                  <div style={{ display: "flex" }}>
                    <Checkbox checked={withPhonesOnly} onClick={() => onChange({ withPhonesOnly: !withPhonesOnly })} />
                    <FormField.Label style={{ marginLeft: 10 }}>Only with Phones</FormField.Label>
                  </div>
                </Form.Row>
                <Form.Row>
                  <div style={{ display: "flex" }}>
                    <Checkbox checked={withEmailsOnly} onClick={() => onChange({ withEmailsOnly: !withEmailsOnly })} />
                    <FormField.Label style={{ marginLeft: 10 }}>Only with Emails</FormField.Label>
                  </div>
                </Form.Row>
              </div>
            </details>
          </div>
          <div className={css.divider} />
          <div className={css.filtersGroup}>
            <details open>
              <summary style={{ position: "relative" }}>
                <Icon className={css.detailsArrow} name="fa-chevron" />
                Skills
              </summary>
              <div className={css.formDetails}>
                {!!window.searchPillBoxes ? (
                  <>
                    {skillsMode === "bool" ? (
                      <>
                        <Form.Row>
                          <FormField
                            label={
                              <>
                                Must Have{" "}
                                <ButtonHTML
                                  onClick={this.showEditSkillsModal}
                                  style={{ position: "relative", left: 2, top: 2 }}
                                >
                                  <Icon name="expand" />
                                </ButtonHTML>
                              </>
                            }
                            style={{ position: "relative" }}
                          >
                            <TextareaHighlightable
                              onChange={(e) => onChange({ skills: e.target.value })}
                              placeholder="e.g. Marketing"
                              value={skills}
                              clearable
                            />
                            <InputMode
                              style={{ position: "absolute", fontSize: 14, top: 0, right: 0 }}
                              onChange={(v) => onChange({ skillsMode: v })}
                              value={skillsMode}
                              options={SKILLS_MODE_OPTIONS}
                            />
                          </FormField>
                        </Form.Row>
                        <Form.Row>
                          <FormField
                            label={
                              <>
                                Nice to Have{" "}
                                <ButtonHTML
                                  onClick={this.showEditSkillsShouldModal}
                                  style={{ position: "relative", left: 2, top: 2 }}
                                >
                                  <Icon name="expand" />
                                </ButtonHTML>
                              </>
                            }
                            style={{ position: "relative" }}
                          >
                            <TextareaHighlightable
                              onChange={(e) => onChange({ skillsShould: e.target.value })}
                              placeholder="e.g. Marketing"
                              value={skillsShould}
                              clearable
                            />
                          </FormField>
                        </Form.Row>
                      </>
                    ) : (
                      <>
                        <Form.Row>
                          <FormField label="Must Have" style={{ position: "relative" }}>
                            <FreeSelect
                              value={skillsMustSelect.map((item) => ({ value: item, label: item }))}
                              onChange={(v) => {
                                onChange({ skillsMustSelect: v.map((item) => item.value) });
                              }}
                              placeholder={"e.g. Marketing"}
                            />
                            <InputMode
                              style={{ position: "absolute", fontSize: 14, top: 0, right: 0 }}
                              onChange={(v) => onChange({ skillsMode: v })}
                              value={skillsMode}
                              options={SKILLS_MODE_OPTIONS}
                            />
                          </FormField>
                        </Form.Row>
                        <Form.Row>
                          <FormField label="Nice to Have">
                            <FreeSelect
                              value={skillsShouldSelect.map((item) => ({ value: item, label: item }))}
                              onChange={(v) => {
                                onChange({ skillsShouldSelect: v.map((item) => item.value) });
                              }}
                              placeholder={"e.g. Marketing"}
                            />
                          </FormField>
                        </Form.Row>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Form.Row>
                      <FormField
                        label={
                          <>
                            Must Have{" "}
                            <ButtonHTML
                              onClick={this.showEditSkillsModal}
                              style={{ position: "relative", left: 2, top: 2 }}
                            >
                              <Icon name="expand" />
                            </ButtonHTML>
                          </>
                        }
                        style={{ position: "relative" }}
                      >
                        <TextareaHighlightable
                          onChange={(e) => onChange({ skills: e.target.value })}
                          placeholder="e.g. Marketing"
                          value={skills}
                          clearable
                        />
                      </FormField>
                    </Form.Row>
                    <Form.Row>
                      <FormField
                        label={
                          <>
                            Nice to Have{" "}
                            <ButtonHTML
                              onClick={this.showEditSkillsShouldModal}
                              style={{ position: "relative", left: 2, top: 2 }}
                            >
                              <Icon name="expand" />
                            </ButtonHTML>
                          </>
                        }
                        style={{ position: "relative" }}
                      >
                        <TextareaHighlightable
                          onChange={(e) => onChange({ skillsShould: e.target.value })}
                          placeholder="e.g. Marketing"
                          value={skillsShould}
                          clearable
                        />
                      </FormField>
                    </Form.Row>
                  </>
                )}
              </div>
            </details>
          </div>
          <div className={css.divider} />
          <div className={css.filtersGroup}>
            <details open>
              <summary style={{ position: "relative" }}>
                <Icon className={css.detailsArrow} name="fa-chevron" />
                Work Experience
              </summary>
              <div className={css.formDetails}>
                <Form.Row>
                  {/* @fixme temporary inline styles */}
                  <FormField
                    label={
                      <>
                        Job Title{" "}
                        <ButtonHTML onClick={this.showEditTitleModal} style={{ position: "relative", left: 2, top: 2 }}>
                          <Icon name="expand" />
                        </ButtonHTML>
                      </>
                    }
                    style={{ position: "relative" }}
                  >
                    <TextareaHighlightable
                      onChange={(e) => onChange({ title: e.target.value })}
                      placeholder="e.g. Sales"
                      value={title}
                      clearable
                    />
                    <InputMode
                      style={{ position: "absolute", fontSize: 14, top: 0, right: 0 }}
                      onChange={(v) => onChange({ titleMode: v })}
                      value={titleMode}
                      options={TITLE_MODE_OPTIONS}
                    />
                  </FormField>
                </Form.Row>
                <Form.Row>
                  <FormField label={
                    <>
                      Company Name{" "}
                      <ButtonHTML
                        onClick={this.showEditCompanyNameModal}
                        style={{ position: "relative", left: 2, top: 2 }}
                      >
                        <Icon name="expand" />
                      </ButtonHTML>
                    </>
                  } style={{ position: "relative" }}>
                    <TextareaHighlightable
                      onChange={(e) => onChange({ employer: e.target.value })}
                      placeholder={"e.g. ChatterWorks"}
                      value={employer}
                      clearable
                    />
                    <InputMode
                      style={{ position: "absolute", fontSize: 14, top: 0, right: 0 }}
                      onChange={(v) => onChange({ companyNameMode: v })}
                      value={companyNameMode}
                      options={COMPANY_NAME_OPTIONS}
                    />
                  </FormField>
                </Form.Row>
                <Form.Row>
                  <FormField label="Industries">
                    <Select
                      isMulti
                      options={INDUSTRY_OPTIONS}
                      onChange={(industries) => onChange({ industries: industries.map((item) => item.value) })}
                      value={INDUSTRY_OPTIONS.filter((item) => industries.includes(item.value))}
                      placeholder={"Select..."}
                    />
                  </FormField>
                </Form.Row>
                <Form.Row>
                  <FormField label="Current Company Size">
                    <Select
                      isMulti
                      options={COMPANY_SIZE_OPTIONS}
                      onChange={(currentCompanySize) =>
                        onChange({ currentCompanySize: currentCompanySize.map((item) => item.value) })
                      }
                      value={COMPANY_SIZE_OPTIONS.filter((item) => currentCompanySize.includes(item.value))}
                    />
                  </FormField>
                </Form.Row>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FormField
                    label="Min Years"
                    style={{ display: "flex", alignItems: "center" }}
                    labelStyle={{ marginBottom: 0 }}
                  >
                    <Input
                      style={{ width: 52, marginLeft: 10, marginRight: 15 }}
                      autoComplete={AUTOCOMPLETE_OFF}
                      onChange={(e) => onChange({ minYears: e.target.value })}
                      value={minYears}
                      placeholder="-"
                    />
                  </FormField>
                  <FormField
                    label="Max Years"
                    style={{ display: "flex", alignItems: "center" }}
                    labelStyle={{ marginBottom: 0 }}
                  >
                    <Input
                      style={{ width: 52, marginLeft: 10, marginRight: 0 }}
                      autoComplete={AUTOCOMPLETE_OFF}
                      onChange={(e) => onChange({ maxYears: e.target.value })}
                      value={maxYears}
                      placeholder="-"
                    />
                  </FormField>
                </div>
              </div>
            </details>
          </div>
          {/*<div className={css.divider} />*/}
          {/* <div className={css.filtersGroup}> */}
          {/* <FormField.Label>Only candidates with</FormField.Label> */}
          {/* <Form.Row> */}
          {/* <div style={{ display : "flex" }}> */}
          {/* <Checkbox checked={withPhonesOnly} onClick={() => onChange({ withPhonesOnly : !withPhonesOnly })} /> */}
          {/* <FormField.Label style={{ marginLeft : 10 }}>Phones</FormField.Label> */}
          {/* </div> */}
          {/* <div style={{ display : "flex" }}> */}
          {/* <Checkbox checked={withEmailsOnly} onClick={() => onChange({ withEmailsOnly : !withEmailsOnly })} /> */}
          {/* <FormField.Label style={{ marginLeft : 10 }}>Emails</FormField.Label> */}
          {/* </div> */}
          {/* </Form.Row> */}
          {/* </div> */}
          <div className={css.divider} />
          <div className={css.filtersGroup}>
            <details open>
              <summary style={{ position: "relative" }}>
                <Icon className={css.detailsArrow} name="fa-chevron" />
                Location
              </summary>
              <div className={css.formDetails}>
                <Form.Row>
                  <FormField label="Country">
                    <Select
                      name="country"
                      placeholder=""
                      onChange={(country) => onChange({ country })}
                      value={country}
                      options={DiscoverSearchLocationHelper.getCountryOptions()}
                    />
                  </FormField>
                </Form.Row>
                <InputLocationArray
                  value={addresses}
                  country={country}
                  onChange={(addresses) => onChange({ addresses })}
                />
              </div>
            </details>
          </div>
          <div className={css.divider} />
          <div className={css.filtersGroup}>
            <details open>
              <summary style={{ position: "relative" }}>
                <Icon className={css.detailsArrow} name="fa-chevron" />
                Education
              </summary>
              <div className={css.formDetails}>
                <Form.Row>
                  <FormField label={
                    <>
                      School{" "}
                      <ButtonHTML
                        onClick={this.showEditSchoolModal}
                        style={{ position: "relative", left: 2, top: 2 }}
                      >
                        <Icon name="expand" />
                      </ButtonHTML>
                    </>
                  }>
                    <TextareaHighlightable
                      autoComplete={AUTOCOMPLETE_OFF}
                      value={school}
                      onChange={(e) => onChange({ school: e.target.value })}
                      placeholder={"e.g. Harvard"}
                    />
                  </FormField>
                </Form.Row>
                <Form.Row>
                  <FormField label="Degree">
                    <Select
                      isMulti
                      options={DISCOVER_DEGREE_OPTIONS}
                      onChange={(degree) => onChange({ degree: degree.map((item) => item.value) })}
                      value={DISCOVER_DEGREE_OPTIONS.filter((item) => degree.includes(item.value))}
                    />
                  </FormField>
                </Form.Row>
                <FormField label="Major / Minor">
                  <Select
                    isMulti
                    options={EDUCATION_MAJOR_OPTIONS}
                    onChange={(major) => onChange({ major: major.map((item) => item.value) })}
                    value={EDUCATION_MAJOR_OPTIONS.filter((item) => major.includes(item.value))}
                  />
                </FormField>
              </div>
            </details>
          </div>
          <div className={css.divider} />
          <div className={css.filtersGroup}>
            <details open>
              <summary style={{ position: "relative" }}>
                <Icon className={css.detailsArrow} name="fa-chevron" />
                Personal Details
              </summary>
              <div className={css.formDetails}>
                <Form.Row>
                  <FormField label="First Name">
                    <Input
                      autoComplete={AUTOCOMPLETE_OFF}
                      onChange={(e) => onChange({ firstName: e.target.value })}
                      value={firstName}
                      placeholder=""
                    />
                  </FormField>
                  <FormField label="Last Name">
                    <Input
                      autoComplete={AUTOCOMPLETE_OFF}
                      onChange={(e) => onChange({ lastName: e.target.value })}
                      value={lastName}
                      placeholder=""
                    />
                  </FormField>
                </Form.Row>
              </div>
            </details>
          </div>
          <UserSettingsGuard setting={UserSettingName.advancedSearch}>
            <>
              <div className={css.divider} />
              <div className={css.filtersGroup}>
                <Button leftIcon={<Icon name="fa-plus" />} style={{ width : "100%" }}
                        onClick={this.showAdvancedFilterModal}>Advanced Filter</Button>
              </div>
            </>
          </UserSettingsGuard>
          {/* <div className={css.filtersGroup}>
            {/* <FormField.Label>Only candidates with</FormField.Label>
            <div style={{ height: 10 }} />
            <Form.Row>
              <div style={{ display: "flex" }}>
                <Checkbox checked={withPhonesOnly} onClick={() => onChange({ withPhonesOnly: !withPhonesOnly })} />
                <FormField.Label style={{ marginLeft: 10 }}>Phones</FormField.Label>
              </div>
              <div style={{ display: "flex" }}>
                <Checkbox checked={withEmailsOnly} onClick={() => onChange({ withEmailsOnly: !withEmailsOnly })} />
                <FormField.Label style={{ marginLeft: 10 }}>Emails</FormField.Label>
              </div>
            </Form.Row>
            <Form.Row>
              <FormField label="First Name">
                <Input
                  autoComplete={AUTOCOMPLETE_OFF}
                  onChange={(e) => onChange({ firstName: e.target.value })}
                  value={firstName}
                  placeholder=""
                />
              </FormField>
              <FormField label="Last Name">
                <Input
                  autoComplete={AUTOCOMPLETE_OFF}
                  onChange={(e) => onChange({ lastName: e.target.value })}
                  value={lastName}
                  placeholder=""
                />
              </FormField>
            </Form.Row>
          </div> */}
        </main>
        {showSaveSearchModal && (
          <NewSavedSearchModal onSubmit={onSave} isOpen={showSaveSearchModal} onClose={this.hideSaveSearchModal} />
        )}
        {showEditTitleModal && (
          <ModalEdit
            onSubmit={this.onSaveEditTitle}
            onClose={this.hideEditTitleModal}
            title="Edit Field"
            config={[{ name: "title", label: "Job Title", defaultValue: title }]}
          />
        )}
        {showEditSkillsModal && (
          <ModalEdit
            onSubmit={this.onSaveEditSkills}
            onClose={this.hideEditSkillsModal}
            title="Edit Field"
            config={[{ name: "skills", label: "Skills: Must Have", defaultValue: skills }]}
          />
        )}
        {showEditSkillsShouldModal && (
          <ModalEdit
            onSubmit={this.onSaveEditSkillsShould}
            onClose={this.hideEditSkillsShouldModal}
            title="Edit Field"
            config={[{ name: "skillsShould", label: "Skills: Nice to Have", defaultValue: skillsShould }]}
          />
        )}
        {showEditCompanyNameModal && (
          <ModalEdit
            onSubmit={this.onSaveEditCompanyName}
            onClose={this.hideEditCompanyNameModal}
            title="Edit Field"
            config={[{ name: "employer", label: "Company Name", placeholder: 'e.g. ChatterWorks', defaultValue: employer }]}
          />
        )}
        {showEditSchoolModal && (
          <ModalEdit
            onSubmit={this.onSaveEditSchool}
            onClose={this.hideEditSchoolModal}
            title="Edit Field"
            config={[{ name: "school", label: "School", placeholder: 'e.g. Harward', defaultValue: school }]}
          />
        )}
        {showAdvancedFilterModal && (
          <ModalAdvancedFilters
            ket={JSON.stringify(advanced)}
            defaultValue={advanced}
            onApply={this.onApplyAdvancedFilter}
            onClose={this.hideAdvancedFilterModal}
          />
        )}
      </div>
    );
  }
}
