import React from "react";
import { css, cx } from "emotion/macro";

// Candidate Information
import CandidateDetailSection, { CandidateDetailSectionStyles } from "../candidate/CandidateDetailSection";
import CandidateProjects from "../candidate/CandidateProjects";
import Education from "../candidate/CandidateEducation";
import Skills from "../candidate/CandidateSkills";
import Experiences from "../candidate/CandidateExperience";

// Insights
import InsightsDetail from "../insights/InsightDetail";

// Notes
import NoteDetail from "../note/NoteDetail";

// Navigator
import Navigator from "./Navigator";
import NavigationTabContainer from "./NavigationTabContainer";

import "../../components/modals/candidateDetails.css";
import { md } from "../../css/mediaQueries";

import { canUserViewInsights } from "../../utils/candidateFeatures";
import educationImg from "../../assets/images/education-avatar.svg";
import capitalizeWords from "../../formatters/capitalizeWords";

const generateNavigationButtons = ({ user, uiSettings }) => {
  if (canUserViewInsights(user)) {
    return [
      { text: "Insights", pageName: "insights" },
      { text: "Details", pageName: "detail" },
      { text: `${uiSettings?.mappings?.projects}`, pageName: "projects" },
      { text: "Notes", pageName: "notes" },
    ];
  } else {
    return [
      { text: "Details", pageName: "detail" },
      { text: `${uiSettings?.mappings?.projects}`, pageName: "projects" },
      { text: "Notes", pageName: "notes" },
    ];
  }
};

const NavigatorPage = ({ name, children }) => {
  return children;
};

function NavigatorWrapper({
  currentPage,
  setCurrentPage,
  candidate,
  user,
  uiSettings,
  updateProjectStage,
  experiences,
  certifications,
  education,
  skills,
  candidateNotes,
  companiesProjects,
  editAndFetchNotes,
}) {

  const [searchRanking, setSearchRanking] = React.useState(true);

  return (
    <Navigator
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      candidate={candidate}
      buttons={generateNavigationButtons({ user, uiSettings })}
    >
      <NavigatorPage name={"insights"}>
        <InsightsDetail candidate={candidate} />
      </NavigatorPage>

      <NavigatorPage name={"detail"}>
        <NavigationTabContainer className={styles.detailContainer}>
          {/* {candidate?.searchRankings && !!Object.keys(candidate?.searchRankings).length && (
            <>
              <span
                className={CandidateDetailSectionStyles.candidateProfileInfoHeader}
                style={{ cursor: "pointer", userSelect: "none" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setSearchRanking(!searchRanking);
                }}
              >
                {"Sort by relevancy: "}<span style={{color: searchRanking ? "#208bfe" : "#373737", fontWeight: 'bold' }}>{searchRanking ? "Enabled" : "Disabled"}</span>
              </span>
              <div style={{height: 10}}/>
            </>
          )} */}
          <CandidateDetailSection title={"experience"} showViewMore={experiences.length > 3}>
            <Experiences entries={experiences} ranked={candidate?.searchRankings?.rankedExperiences ?? null}/>
          </CandidateDetailSection>
          <CandidateDetailSection title={"education"} showViewMore={education.length > 2}>
            <Education entries={education} maxEntries={2} />
          </CandidateDetailSection>
          {!!certifications?.length > 0 && (
            <CandidateDetailSection title={"Certifications"}>
              {certifications.map((item, i) => {
                return (
                  <div key={i} style={{ display : "flex", gap: 18, marginBottom : "12px" }}>
                    <img
                      className="job-img"
                      src={educationImg}
                      alt=""
                      width="60px"
                      style={{
                        flexShrink: 0,
                        borderRadius : "50%",
                        marginTop : "auto",
                        marginBottom : "auto",
                      }}
                    />
                    <div>
                      <div className={cx("standard-theme-text", "candidate-detail-list-item-title")}>
                        {capitalizeWords(item.organization || 'Unknown')}
                      </div>
                      <div className={cx("standard-theme-text", "candidate-detail-list-item-details")}>
                        {capitalizeWords(item.name || 'Unknown')}
                      </div>
                    </div>
                  </div>
                )
              })}
            </CandidateDetailSection>
          )}
          {skills.length > 0 && (
            <CandidateDetailSection title={"Featured Skills"} showViewMore={skills.length > 3}>
              <Skills entries={skills} ranked={candidate?.searchRankings?.rankedSkills ?? null} />
            </CandidateDetailSection>
          )}
        </NavigationTabContainer>
      </NavigatorPage>

      <NavigatorPage name={"projects"}>
        <NavigationTabContainer className={styles.projectContainer}>
          {candidate && (
            <CandidateProjects
              uiSettings={uiSettings}
              candidate={candidate}
              hasStaticFields={false}
              updateProjectStage={updateProjectStage}
            />
          )}
        </NavigationTabContainer>
      </NavigatorPage>

      <NavigatorPage name={"notes"}>
        <NavigationTabContainer className={styles.notesContainer}>
          {candidateNotes.length > 0 ? (
            candidateNotes.map((note, idx) => (
              <NoteDetail
                key={JSON.stringify(note)}
                note={note}
                bottomBorder={idx === companiesProjects.length - 1}
                onEditNoteSaved={editAndFetchNotes}
                isStaticNote={false}
              />
            ))
          ) : (
            <div style={{ textAlign : "center" }}>
              <span
                className={cx(css`
                  font-size: 12px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: -0.33px;
                  text-align: center;
                  color: #9b9b9b;
                `)}
              >
                No Notes
              </span>
            </div>
          )}
        </NavigationTabContainer>
      </NavigatorPage>
    </Navigator>
  );
}

const styles = {
  detailContainer: css`
    margin: 24px 0px;
    overflow-y: auto;
    height: 100%;
    height: calc(100% - 60px);
    padding-right: 10px;
    ${md} {
      padding: 0 30px;
      margin-top: 0px;
      max-height: initial;
    }
  `,
  projectContainer: css`
    margin: 24px 0px;
    overflow-y: auto;
    height: 100%;
    max-height: calc(100% - 60px);
    padding-right: 10px;
    ${md} {
      padding: 0 30px;
      margin-top: 0px;
      max-height: calc(100% - 125px);
    }
  `,
  notesContainer: css`
    margin: 24px 0px;
    overflow-y: auto;
    height: 100%;
    max-height: calc(100% - 60px);
    padding-right: 5px;
    ${md} {
      padding: 0 30px;
      margin-top: 0px;
      max-height: initial;
    }
  `,
};

export default NavigatorWrapper;
