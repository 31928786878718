import queryString from "query-string";
import {
  handleRequestAccessToken,
  handleResponseAccountSuspended,
  handleResponseInvalidAccessToken,
} from "./apiMiddlewares";
import { UrlHelper } from "../components/DiscoverNew/util/UrlHelper";

const axiosBase = require("axios");
export const axios = axiosBase.create();
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.interceptors.response.use(handleResponseAccountSuspended.onSuccess, handleResponseAccountSuspended.onError);
axios.interceptors.request.use(handleRequestAccessToken.onSuccess, handleRequestAccessToken.onError);
axios.interceptors.response.use(handleResponseInvalidAccessToken.onSuccess, handleResponseInvalidAccessToken.onError);

export const axiosApiV2 = axiosBase.create();
axiosApiV2.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axiosApiV2.interceptors.response.use(handleResponseAccountSuspended.onSuccess, handleResponseAccountSuspended.onError);
axiosApiV2.interceptors.request.use(handleRequestAccessToken.onSuccess, handleRequestAccessToken.onError);
axiosApiV2.interceptors.response.use(handleResponseInvalidAccessToken.onSuccess, handleResponseInvalidAccessToken.onError);

if (process.env.NODE_ENV !== 'production') {
  // Tunnel header
  axios.defaults.headers.common["ngrok-skip-browser-warning"] = null
}
export const adminAxios = axiosBase.create();
adminAxios.defaults.baseURL = process.env.REACT_APP_ADMIN_API_BASE_URL;
adminAxios.interceptors.response.use(handleResponseAccountSuspended.onSuccess, handleResponseAccountSuspended.onError);
adminAxios.interceptors.request.use(handleRequestAccessToken.onSuccess, handleRequestAccessToken.onError);
adminAxios.interceptors.response.use(
  handleResponseInvalidAccessToken.onSuccess,
  handleResponseInvalidAccessToken.onError
);

/**
 * @deprecated
 */
export function SetAPIKey(key) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${key}`;
  adminAxios.defaults.headers.common["Authorization"] = `Bearer ${key}`;
}

// --------- ADMIN -----------

export async function AdminGetOrganizations(args) {
  return adminAxios.get(`/organizations?${queryString.stringify(args)}`, args);
}

export async function AdminGetOrganizationsCount(args) {
  return adminAxios.get(`/organizations/count?${queryString.stringify(args)}`, args);
}

export async function AdminGetOrganizationSeatCreditsUsage(userId) {
  return adminAxios.get(`/users/${userId}/organization-seat/usage/credits`);
}

export async function AdminGetOrganization(organizationId) {
  return adminAxios.get(`/organizations/${organizationId}`);
}

export async function AdminGetOrganizationCreditsUsageBySeat(organizationId) {
  return adminAxios.get(`/organizations/${organizationId}/subscription/usage/credits/by-seat`);
}

export async function AdminGetUserCreditsUsage(userId) {
  return adminAxios.get(`/users/${userId}/organization-seat/usage/credits`);
}

export async function AdminGetOrganizationExtraCredits(organizationId) {
  return adminAxios.get(`/organizations/${organizationId}/extra-credits`);
}

export async function AdminUpdateOrganization(organizationId, data) {
  return adminAxios.put(`/organizations/${organizationId}`, data);
}

export async function AdminGetUsers(args) {
  return adminAxios.get(`/users?${queryString.stringify(args)}`, args);
}

export async function AdminGetUsersCount(args) {
  return adminAxios.get(`/users/count?${queryString.stringify(args)}`, args);
}

export async function AdminGetUser(userId) {
  return adminAxios.get(`/users/${userId}`);
}

export async function AdminUpdateUser(userId, data) {
  return adminAxios.put(`/users/${userId}`, data);
}

export async function AdminLoginAs(userId) {
  return adminAxios.post(`/users/${userId}/login-as`);
}

export async function AdminActivateUser(userId) {
  return adminAxios.post(`/users/${userId}/activate`);
}

export async function AdminAddOrganizationExtraCredits(organizationId, data) {
  return adminAxios.post(`/organizations/${organizationId}/extra-credits`, data);
}

export async function AdminCreateOrganizationSubscription(organizationId, data) {
  return adminAxios.post(`/organizations/${organizationId}/subscriptions`, data);
}

export async function AdminUpdateOrganizationSubscription(organizationId, subscriptionId, data) {
  return adminAxios.put(`/organizations/${organizationId}/subscriptions/${subscriptionId}`, data);
}

export async function AdminGetBlacklistDomains(args) {
  return adminAxios.get(`/blacklist/domains?${queryString.stringify(args)}`, args);
}

export async function AdminGetBlacklistDomainsCount(args) {
  return adminAxios.get(`/blacklist/domains/count?${queryString.stringify(args)}`, args);
}

export async function AdminAddBlacklistDomain(data) {
  return adminAxios.post(`/blacklist/domains`, data);
}

export async function AdminUpdateBlacklistDomain(id, data) {
  return adminAxios.put(`/blacklist/domains/${id}`, data);
}

export async function AdminEnableBlacklistDomain(id, data) {
  return adminAxios.post(`/blacklist/domains/${id}/enable`, data);
}

export async function AdminGetUserWhitelistEmails(args) {
  return adminAxios.get(`/allowed-emails?${queryString.stringify(args)}`, args);
}

export async function AdminGetUserWhitelistEmailsCount(args) {
  return adminAxios.get(`/allowed-emails/count?${queryString.stringify(args)}`, args);
}

export async function AdminCreateUserWhitelistEmail(data) {
  return adminAxios.post(`/allowed-emails`, data);
}
export async function UpdateUserWhitelistEmail(id, data) {
  return adminAxios.put(`/allowed-emails/${id}`, data);
}
export async function AdminDeleteUserWhitelistEmail(id) {
  return adminAxios.delete(`/allowed-emails/${id}`);
}
export async function AdminGetUserSettings(id) {
  return adminAxios.get(`/user_settings/${id}`);
}

// "Create" part of the name kept to follow the name convention. (e.g. POST requests are prefixed by "Create" part)
export async function AdminCreateUserSettings(id, data) {
  return adminAxios.post(`/user_settings/${id}`, data);
}

// --------- ADMIN -----------

// User Profile Section
export async function RefreshAccessToken(data) {
  try {
    const response = await axios.post(`/auth/token`, data);
    return response;
  } catch (error) {
    return error;
  }
}

// User Profile Section
export async function GetCurrentUser() {
  try {
    const response = await axios.get(`/user`);
    return response;
  } catch (error) {
    return error;
  }
}

export async function ProcessSubscription(data) {
  return axios.post(`/user/process-subscription`, data);
}

export async function UpdateUserProfile({ firstName, lastName, phoneNumbers }) {
  try {
    const response = await axios.put(`/user`, {
      firstName,
      lastName,
      phoneNumbers,
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function UploadUserProfileImage(imageBlob, extension) {
  var formData = new FormData();
  formData.append("profileImage", imageBlob);
  formData.append("extension", extension);

  try {
    const response = await axios.put(`/user/profile-img`, formData, {
      "Content-Type": "multipart/form-data",
    });
    return response;
  } catch (error) {
    return error;
  }
}

// Candidate Section
export async function GetCandidate(candidateId, projectId) {
  try {
    let response = null;
    if (projectId) {
      response = await axios.get(`/project/${projectId}/candidate/${candidateId}`);
    } else {
      response = await axios.get(`/candidate/${candidateId}`);
    }
    return response;
  } catch (error) {
    return error;
  }
}

export async function ListManuallyCreatedCandidates(orderBy = "candidate_name", isAscending = true, limit, skip, query) {
  const sortBy = isAscending ? "asc" : "desc";
  const queryParams = `?orderBy=${orderBy}&sortBy=${sortBy}&limit=${limit}&skip=${skip}&query=${query || ''}`;

  try {
    const response = await axios.get(`/candidate/organization/created${queryParams}`);
    return response;
  } catch (error) {
    return error;
  }
}

export async function ListManuallyCreatedCandidatesCount(orderBy = "candidate_name", isAscending = true, limit, skip, query) {
  const sortBy = isAscending ? "asc" : "desc";
  const queryParams = `?orderBy=${orderBy}&sortBy=${sortBy}&limit=${limit}&skip=${skip}&query=${query || ''}`;

  try {
    const response = await axios.get(`/candidate/organization/created/count${queryParams}`);
    return response;
  } catch (error) {
    return error;
  }
}

export async function ListMyCandidates(orderBy = "candidate_name", isAscending = true, limit, skip, query) {
  let sortBy = isAscending ? "asc" : "desc";
  let queryParams = `?orderBy=${orderBy}&sortBy=${sortBy}&limit=${limit}&skip=${skip}&query=${query || ''}`;

  try {
    const response = await axios.get(`/candidate/mine${queryParams}`);
    return response;
  } catch (error) {
    return error;
  }
}

export async function ListMyCandidatesCount(orderBy = "candidate_name", isAscending = true, limit, skip, query) {
  let sortBy = isAscending ? "asc" : "desc";
  let queryParams = `?orderBy=${orderBy}&sortBy=${sortBy}&limit=${limit}&skip=${skip}&query=${query || ''}`;

  try {
    const response = await axios.get(`/candidate/mine/count${queryParams}`);
    return response;
  } catch (error) {
    return error;
  }
}

export async function GetStageFlowForProject(projectId) {
  if (!projectId) return;
  try {
    const response = await axios.get(`/project/${projectId}/interview-process`);
    return response;
  } catch (error) {
    return error;
  }
}

export async function UpdateProjectStage(projectId, stageId, data) {
  if (!projectId) return;
  try {
    const response = await axios.put(`/project/${projectId}/stages/${stageId}`, data);
    return response;
  } catch (error) {
    return error;
  }
}

export async function UpdateCandidateStage(projectId, candidateId, stageId) {
  if (!projectId || !candidateId || !stageId) return;
  try {
    const response = await axios.post(`/project/${projectId}/candidate/${candidateId}/stage`, {
      stageId: stageId,
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function DownloadCandidatePDF(candidateId) {
  try {
    const response = await axios.get(`/candidate/${candidateId}/pdf`, {
      responseType: "blob",
      headers: {
        Accept: "application/octet-stream",
      },
    });
    return response;
  } catch (error) {
    return error;
  }
}

/**
 * @param {string} projectId
 * @param {Object} data
 * @param {string} data.email
 * @param {('admin'|'editor'|'viewer')} data.role
 * @returns {Promise<any>}
 */
export async function InviteProjectCollaborator(projectId, data) {
  return axios.post(`/project/${projectId}/invite`, data);
}

/**
 * @param {string} id
 * @param {Object} data
 * @param {('accepted'|'declined')} data.status
 * @returns {Promise<any>}
 */
export async function RespondProjectInvite(id, data) {
  return axios.put(`/project/invite/${id}/respond`, data);
}

/**
 * @param {string} token
 * @returns {Promise<any>}
 */
export async function GetProjectInvite(token) {
  return axios.get(`/project/invite/${token}`);
}

/**
 * @returns {Promise<any>}
 */
export async function GetProjectInviteMine() {
  return axios.get(`/project/invite/mine`);
}

/**
 * @returns {Promise<any>}
 * @param {string} projectId
 */
export async function MarkProjectCSVExportAsClaimed(projectId) {
  try {
    return await axios.post(`/project/csv-export/${projectId}/claim`);
  } catch (error) {
    return error;
  }
}

/**
 * @returns {AxiosResponse<Array<{
 *   id: string;
 *   project: {
 *     id: string;
 *     name: string;
 *   }
 *   status: 'queued' | 'processing' | 'finished';
 *   downloadLink: string;
 *   startedAt: string;
 *   finishedAt: string;
 * }>>}
 */
export async function GetAllProjectExportsMine() {
  try {
    return await axios.get(`/project/csv-export/mine`);
  } catch (error) {
    return error;
  }
}

/**
 * @param {string} projectId
 * @param {string} collaboratorId
 * @returns {Promise<any>}
 */
export async function DeleteProjectCollaborator(projectId, collaboratorId) {
  return axios.delete(`/project/${projectId}/collaborator/${collaboratorId}`);
}

/**
 * @param {string} projectId
 * @param {string} collaboratorId
 * @param {Object} data
 * @param {('admin'|'editor'|'viewer')} data.role
 * @returns {Promise<any>}
 */
export async function UpdateProjectCollaborator(projectId, collaboratorId, data) {
  return axios.put(`/project/${projectId}/collaborator/${collaboratorId}`, data);
}

/**
 * @param {string} projectId
 * @returns {Promise<any>}
 */
export async function GetProjectCollaborators(projectId) {
  return axios.get(`/project/${projectId}/collaborators`);
}

export async function GetProjectCandidates(projectId, args) {
  try {
    const response = await axios.get(`/project/${projectId}/candidate?${queryString.stringify(args)}`);
    return response;
  } catch (error) {
    return error;
  }
}

export async function GetProjectCandidatesCount(projectId, args) {
  try {
    const response = await axios.get(`/project/${projectId}/candidate/count?${queryString.stringify(args)}`);
    return response;
  } catch (error) {
    return error;
  }
}

export async function AddCandidatesToProject(projectId, candidateIds) {
  if (!candidateIds?.length) {
    return;
  }
  return axios.post(`/project/${projectId}/candidate`, {
    candidateIDs: candidateIds,
  });
}

export async function RemoveCandidatesFromProject(projectId, candidateIDs) {
  try {
    const response = await axios.delete(`/project/${projectId}/candidate`, { data: { candidateIDs } });
    return response;
  } catch (error) {
    return error;
  }
}

export async function DeleteManuallyCreatedCandidate(candidateId) {
  try {
    const response = await axios.delete(`/candidate/${candidateId}`);
    return response;
  } catch (error) {
    return error;
  }
}

export async function GetCandidateProjects(candidateId) {
  try {
    const response = await axios.get(`/project?candidateId=${candidateId}`);
    return response;
  } catch (error) {
    return error;
  }
}

export async function UnlockCandidate(candidateId) {
  return axios.post(`/candidate/${candidateId}/unlock`);
}

export async function RequestPhoneNumber(candidateId) {
  try {
    const response = await axios.post(`/candidate/${candidateId}/connection`);
    return response;
  } catch (error) {
    return error;
  }
}

// Candidate Notes Section
export async function GetCandidateNotes(candidateId) {
  try {
    const response = await axios.get(`/candidate/${candidateId}/note`);
    return {
      ...response,
      data: response.data.map((note) => {
        return {
          ...note,
          noteId: note.id,
          note: note.body
        };
      })
    };
  } catch (error) {
    return error;
  }
}

export async function CreateCandidateNote(candidateId, note) {
  try {
    const response = await axios.post(`/candidate/${candidateId}/note`, {
      body: note,
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function UpdateCandidateNote(candidateId, { noteId, note }) {
  try {
    const response = await axios.put(`/candidate/${candidateId}/note/${noteId}`, {
      body: note,
    });
    return response;
  } catch (error) {
    return error;
  }
}

/**
 * @typedef {Object} ListProjectArgs
 * @param {number} args.candidateId
 * @param {number} args.companyId
 * @param {("mine"|"shared")} args.ownership
 * @param {boolean} args.archived
 * @param {("asc"|"desc")} args.orderBy
 * @param {("company_name"|"project_name"|"most_recent")} args.sortBy
 * @param {string} args.query
 * @param {number} args.limit
 * @param {number} args.skip
 */

/**
 * @param {ListProjectArgs} args
 * @returns {Promise<any>}
 */
export async function ListProjects(args) {
  return axios.get(`/project?${queryString.stringify(args)}`);
}

/**
 * @param {string} id
 * @returns {Promise<any>}
 */
export async function GetProject(id) {
  return axios.get(`/project/${id}`);
}

/**
 * @param {ListProjectArgs} args
 * @returns {Promise<any>}
 */
export async function ListProjectsCount(args) {
  return axios.get(`/project/count?${queryString.stringify(args)}`);
}

/**
 * @param {Object} data
 * @param {string} data.name
 * @param {string} data.description
 * @param {string} data.companyId
 * @param {string[]} data.tags
 * @returns {Promise<any>}
 */
export async function CreateProject(data) {
  return axios.post("/project", data);
}

export async function UpdateProject(projectId, data) {
  try {
    const response = await axios.put(`/project/${projectId}`, data);
    return response;
  } catch (error) {
    return error;
  }
}

export async function MoveToCompany(projectId, data) {
  try {
    const response = await axios.put(`/project/${projectId}/company`, data);
    return response;
  } catch (error) {
    return error;
  }
}

export async function DeleteProject(projectId) {
  try {
    const response = await axios.delete(`/project/${projectId}`);
    return response;
  } catch (error) {
    return error;
  }
}

export async function ArchiveProjectCandidate(projectId, candidateId) {
  try {
    const response = await axios.put(`/project/${projectId}/candidate/${candidateId}/archive`, { archived: true });
    return response;
  } catch (error) {
    return error;
  }
}

export async function UnarchiveProjectCandidate(projectId, candidateId) {
  try {
    const response = await axios.put(`/project/${projectId}/candidate/${candidateId}/archive`, { archived: false });
    return response;
  } catch (error) {
    return error;
  }
}

export async function ArchiveProject(projectId) {
  try {
    const response = await axios.put(`/project/${projectId}/archive`, { archived: true });
    return response;
  } catch (error) {
    return error;
  }
}

export async function UnarchiveProject(projectId) {
  try {
    const response = await axios.put(`/project/${projectId}/archive`, { archived: false });
    return response;
  } catch (error) {
    return error;
  }
}

export async function DownloadProjectCSV(projectId, verifiedOnly, force) {
  try {
    let url = `/project/${projectId}/export/csv?`;
    const args = [];
    if (verifiedOnly) {
      args.push("filterEmails=true");
    }
    if (force) {
      args.push("force=true");
    }
    url = url += args.join("&");
    const response = await axios.get(url);
    return response;
  } catch (error) {
    return error;
  }
}

// Company section
export async function GetCompany(companyId) {
  try {
    return await axios.get(`/company/${companyId}`);
  } catch (error) {
    return error;
  }
}

export async function CreateCompany(name, website) {
  try {
    return await axios.post("/company", {
      name,
      website,
    });
  } catch (error) {
    return error;
  }
}

export async function UpdateCompany({ companyId, name, website }) {
  try {
    const response = await axios.put(`/company/${companyId}`, {
      name,
      website,
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function GetCompanies(orderBy = "company_name", isAscending = true) {
  let sortBy = isAscending ? "asc" : "desc";
  let queryParams = `?orderBy=${orderBy}&sortBy=${sortBy}&limit=100`;

  try {
    return await axios.get(`/company${queryParams}`);
  } catch (error) {
    return error;
  }
}

export async function GetAllCompaniesAndProjects() {
  try {
    const response = await GetCompanies();
    return response;
  } catch (error) {
    return error;
  }
}

export async function GetCompanyProjects(companyId) {
  try {
    const response = await axios.get(`/project?companyId=${companyId}`);
    return response;
  } catch (error) {
    return error;
  }
}

export async function DeleteCompany(companyId) {
  try {
    return await axios.delete(`/company/${companyId}`);
  } catch (error) {
    return error;
  }
}

// Saved Search Section
export async function GetSavedSearches(orderBy = "name", isAscending = true, limit = -1) {
  let sortBy = isAscending ? "asc" : "desc";
  let queryParams = `?orderBy=${orderBy}&sortBy=${sortBy}&limit=${limit}`;
  try {
    const response = await axios.get(`/search/saved${queryParams}`);
    return response;
  } catch (error) {
    return error;
  }
}

export async function CreateSavedSearch({ name, fields }) {
  try {
    const response = await axios.post("/search/saved", {
      name,
      fields,
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function DeleteSavedSearch(searchId) {
  try {
    return await axios.delete(`/search/saved/${searchId}`);
  } catch (error) {
    return error;
  }
}

export async function SearchCandidates(data) {
  try {
    return axios.post(`/search/candidate/_search`, data);
  } catch (error) {
    return error;
  }
}

// Activity Metrics section
export async function GetSocialActivityMetrics(candidateId, metric) {
  if (!candidateId || !metric) {
    return;
  }

  try {
    const response = await axios.get(`/candidate/${candidateId}/activity?metric=${metric}`);
    return response;
  } catch (error) {
    return error;
  }
}

export async function Logout() {
  try {
    return axios.post(`/auth/logout`);
  } catch (error) {
    return error;
  }
}

export async function ValidateEmails(candidateId) {
  try {
    return axios.post(`/candidate/${candidateId}/validate-emails`);
  } catch (error) {
    return error;
  }
}

export async function EnableWorkableIntegration({ apiKey, subdomain }) {
  try {
    return axios.post(`/integrations/workable/enable`, { apiKey, subdomain });
  } catch (e) {
    return e;
  }
}

export async function GetWorkableJobs(projectId) {
  try {
    return axios.get(`/project/${projectId}/workableJobs`);
  } catch (e) {
    return e;
  }
}

export async function AddCandidatesToWorkable({ candidateIDs, jobId }) {
  try {
    return axios.post(`/integrations/workable/candidates`, { candidateIDs, jobId });
  } catch (e) {
    return e;
  }
}

export async function GetIntegrationList() {
  // todo
  return {
    data: {
      workable: false,
    }
  };
  // try {
  //   return axios.get(`/integrations/list`);
  // } catch (e) {
  //   return e;
  // }
}

export async function DisableWorkableIntegration() {
  try {
    return axios.post(`/integrations/workable/disable`);
  } catch (e) {
    return e;
  }
}

export async function GetOrganizationStageFlowList(organizationId, filter) {
  const filterQuery = UrlHelper.stringifyParams(filter);
  try {
    return axios.get(`/interview-process?${filterQuery}`);
  } catch (e) {
    return e;
  }
}

export async function CreateOrganizationStageFlow(organizationId, data) {
  try {
    return axios.post(`/interview-process`, data);
  } catch (e) {
    return e;
  }
}

export async function UpdateOrganizationStageFlow(organizationId, stageFlowId, data) {
  try {
    return axios.put(`/interview-process/${stageFlowId}`, data);
  } catch (e) {
    return e;
  }
}

export async function ArchiveOrganizationStageFlow(organizationId, stageFlowId, data) {
  try {
    return axios.put(`/interview-process/${stageFlowId}/archive`, data);
  } catch (e) {
    return e;
  }
}

export async function DuplicateOrganizationStageFlow(organizationId, stageFlowId, data) {
  try {
    return axios.post(`/interview-process/${stageFlowId}/copy`, data);
  } catch (e) {
    return e;
  }
}

export async function DeleteOrganizationStageFlow(organizationId, stageFlowId) {
  try {
    return axios.delete(`/interview-process/${stageFlowId}`);
  } catch (e) {
    return e;
  }
}

export async function GetOrganizationStageFlow(organizationId, stageFlowId) {
  try {
    return axios.get(`/interview-process/${stageFlowId}`);
  } catch (e) {
    return e;
  }
}

export async function CreateOrganizationStageFlowStage(organizationId, stageFlowId, data) {
  try {
    return axios.post(`/interview-process/${stageFlowId}/stages`, data);
  } catch (e) {
    return e;
  }
}

export async function UpdateOrganizationStageFlowStage(organizationId, stageFlowId, stageId, data) {
  try {
    return axios.put(`/interview-process/${stageFlowId}/stages/${stageId}`, data);
  } catch (e) {
    return e;
  }
}

export async function DeleteOrganizationStageFlowStage(organizationId, stageFlowId, stageId) {
  try {
    return axios.delete(`/interview-process/${stageFlowId}/stages/${stageId}`);
  } catch (e) {
    return e;
  }
}

export async function CreateCandidateManually(data) {
  try {
    return axios.post(`/candidate/create`, data);
  } catch (e) {
    return e;
  }
}

// "Create" part of the name kept to follow the name convention. (e.g. POST requests are prefixed by "Create" part)
export async function CreateUserSettings(data) {
  try {
    return axios.post(`/user_settings`, data);
  } catch (e) {
    return e;
  }
}

export async function GetUserSettings() {
  try {
    return axios.get(`/user_settings`);
  } catch (e) {
    return e;
  }
}

/**
 * @param file {File}
 * @returns {Promise<*|AxiosResponse<any>>}
 */
export async function ParseCandidateImport(file) {
  var formData = new FormData();
  formData.append("name", 'file');
  formData.append("file", file);
  const response = await axios.post(`/candidate-import/parse`, formData, {
    "Content-Type": "multipart/form-data",
  });
  return response;
}

export async function ConfirmCandidateImport(id, data) {
  return axios.post(`/candidate-import/${id}/confirm`, data);
}