import * as React from "react";
import { useState } from "react";
import { UserSettingName, UserSettings, userSettingsService } from "../services/UserSettingsService";

export const useUserSettings = () => {
  const [loading, setLoading] = useState(false);
  const [userSettings, setUserSettings] = React.useState<UserSettings>({
    [UserSettingName.advancedSearch] : false,
    [UserSettingName.showSearchBar] : false,
    [UserSettingName.luceneSearch] : false,
  });

  React.useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const res = await userSettingsService.fetchSettings();
        setUserSettings(res);
        setLoading(false);
      } catch (err) {
        // todo respond to error
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const onChange = async (obj: Partial<UserSettings>) => {
    const prev = JSON.parse(JSON.stringify(userSettings));
    // optimistic update
    setUserSettings({ ...prev, ...obj });
    setLoading(true);
    try {
      await userSettingsService.updateSettings(obj);
      setLoading(false);
    } catch (err) {
      // todo respond to error
      setLoading(false);
      // revert optimistic changes
      setUserSettings({ ...prev, ...obj });
    }
  };

  return [userSettings, onChange, loading] as [UserSettings, (obj: Partial<UserSettings>) => void, boolean];
};

type Props = {
  children: React.ReactNode;
  setting: UserSettingName;
}

export const UserSettingsGuard = (props: Props) => {
  const [userSettings, onChange, loading] = useUserSettings();
  if (userSettings[props.setting]) {
    return <>{props.children}</>;
  }
  return null;
}