import * as React from "react";
import { DiscoverCandidate } from "../DiscoverCandidate/DiscoverCandidate";
import { Link } from "../../UI/Link/Link";
import { Icon } from "../../UI/IconComponent/Icon";
import { PaginationFull } from "../../UI/PaginationFull/PaginationFull";
import { NoData } from "../../UI/NoData/NoData";
import AddCandidatesToProjectModal from "../../../modals/AddCandidatesToProjectModal";
import { useUserData } from "../../../../contexts/userContext";
import { Loading } from "../../UI/Loading/Loading";

import css from "./DiscoverSearchResults.module.css";
import { Input } from "../../Form/Input/Input";
import { Button } from "../../UI/Button/Button";
import cn from "classnames";
import { UserSettingName } from "../../../../services/UserSettingsService";
import { useUserSettings } from "../../../../hooks/useUserSettings";

export const DiscoverSearchResults = React.memo((props) => {
  const [showAddToProjectModal, setShowAddToProjectModal] = React.useState(false);
  const [userSettings] = useUserSettings();

  const {
    selected,
    result,
    selectedCount,
    onSelect,
    onSelectAll,
    isSelectedAll,
    onClearSelection,
    loading,
    onUnlockCandidate,
    onCandidateUpdated,
    onAddSelectedToProject,
    onAddToProject,
    search,
    onChange
  } = props;

  const {
    state: { user },
  } = useUserData();
  const uiSettings = user.uiSettings;
  const [searchLocal, setSearchLocal] = React.useState("");

  React.useEffect(() => {
    setSearchLocal(search);
  }, [search])

  const onExportSearchToFile = () => {
    const file = new Blob([search], { type: "text/plain" });
    const a = document.createElement("a");
    const url = URL.createObjectURL(file);
    a.href = url;
    a.download = "search.txt";
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }

  const isBooleanSearchFlagEnabled = userSettings[UserSettingName.showSearchBar];
  const controls = (
    <div className={cn(css.controls, isBooleanSearchFlagEnabled && css.hasBooleanSearch)}>
      {!selectedCount ? (
        !!result?.pageInfo?.total && (
          <Link className={css.controlsItem} onClick={onSelectAll}>
            Select All
          </Link>
        )
      ) : (
        <>
          <span className={css.controlsItem}>{selectedCount} selected</span>
          {isSelectedAll ? (
            <Link className={css.controlsItem} onClick={onClearSelection}>
              Clear Selection
            </Link>
          ) : (
            <Link className={css.controlsItem} onClick={onSelectAll}>
              Select All
            </Link>
          )}
          <Link
            className={css.controlsItem}
            leftIcon={<Icon name="plus" />}
            onClick={() => setShowAddToProjectModal(true)}
          >
            Add to {uiSettings?.mappings?.project}
          </Link>
        </>
      )}
    </div>
  )

  return (
    <div className={css.holder}>
      <header className={cn(css.header, isBooleanSearchFlagEnabled && css.hasBooleanSearch)}>
        {isBooleanSearchFlagEnabled ? (
          <div style={{ display : "flex", alignItems : "center", gap : 12 }}>
            <Input onChange={e => setSearchLocal(e.target.value)} value={searchLocal} style={{ minWidth : 500 }}
                   placeholder="Enter your search here" />
            <Button
              disabled={!searchLocal?.length || loading} style={{ minWidth : 110, marginRight : 12 }}
              onClick={() => onChange({ search : searchLocal })}
            >
              {loading ? 'Searching...' : 'Search'}
            </Button>
            <Link disabled={!search?.length} onClick={onExportSearchToFile}>Export</Link>
          </div>
        ) : controls}
      </header>
      <div className={css.list}>
        {isBooleanSearchFlagEnabled && !!result?.pageInfo?.total && controls}
        {loading ? (
          <Loading />
        ) : !result ? (
          <NoData />
        ) : result.pageInfo?.total ? (
          result.data.map((item) => {
            return (
              <DiscoverCandidate
                isSelectable
                onAddToProject={(projectId) => onAddToProject(projectId, item)}
                canAddToProject={selectedCount === 0}
                key={item.id}
                data={item}
                className={css.item}
                onCandidateUpdated={onCandidateUpdated}
                onUnlock={() => onUnlockCandidate(item)}
                onSelect={() => onSelect(item)}
                isSelected={selected[item.id]}
              />
            );
          })
        ) : (
          <NoData text={`No ${uiSettings?.mappings?.candidates} found for your request`} />
        )}
      </div>
      {!!result?.pageInfo?.total && (
        <PaginationFull
          className={css.pagination}
          pageCount={result.pageInfo.pageCount}
          onChangeCurrentPage={props.onChangeCurrentPage}
          currentPage={props.currentPage}
          pageSize={props.pageSize}
          onChangePageSize={props.onChangePageSize}
        />
      )}

      {showAddToProjectModal && (
        <AddCandidatesToProjectModal
          candidates={selected}
          candidatesCount={selectedCount}
          onSubmit={onAddSelectedToProject}
          isOpen={showAddToProjectModal}
          onClose={() => setShowAddToProjectModal(false)}
        />
      )}
    </div>
  );
});
