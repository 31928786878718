import React, { useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import RouteSwitch from "../../components/CustomRouter/RouteSwitch";
import { injectStyleOverride } from "../../components/DiscoverNew/DiscoverNewWrapper";
import ProfileDetails from "./ProfileDetails/ProfileDetails";
import { ProfileStageFlow } from "./ProfileStageFlow/ProfileStageFlow";
import { ProfileStage } from "./ProfileStageFlow/ProfileStage";
import { ProfileIntegrations } from "./ProfileIntegrations/ProfileIntegrations";
import ProfileBilling from "./ProfileBilling/ProfileBilling";
import BillingPayment from "./ProfileBilling/BillingPayment/BillingPayment";
import BillingHistory from "./ProfileBilling/BillingHistory/BillingHistory";
import { useAuthController } from "../../contexts/AuthProvider";
import { PermissionEnum, permissionService } from "../projects/PermissionService";
import BillingMembers from "./ProfileBilling/BillingMembers/BillingMembers";
import ProfileTeamManagement from "./ProfileTeamManagement/ProfileTeamManagement";
import { ProfileFeatureFlags } from "./ProfileFeatureFlags/ProfileFeatureFlags";

const getRoutes = (user) => {
  const canEditBilling = permissionService.can(user, PermissionEnum.editOrganizationBilling);
  const canEditMembers = permissionService.can(user, PermissionEnum.editOrganizationMembers);
  const canEditIntegrations = permissionService.can(user, PermissionEnum.editOrganizationIntegrations);
  const canEditFeatureFlags = permissionService.can(user, PermissionEnum.editFeatureFlags);
  return [
    { path: "/profile/account-details", Component: ProfileDetails, },
    // ...(canEditIntegrations ? [{
    //   path: "/profile/integrations", Component: ProfileIntegrations, exact: true,
    // }] : []),
    { path: "/profile/interview-process", Component: ProfileStageFlow, exact: true, },
    { path: "/profile/interview-process/:id", Component: ProfileStage },
    ...(canEditBilling ? [{
      path: "/profile/billing/:tab?",
      Component: ProfileBilling,
      routes: [
        {
          path : "/profile/billing/plans",
          Component : BillingPayment,
          exact : true,
        },
        {
          path : "/profile/billing/history",
          Component : BillingHistory,
          exact : true,
        },
        {
          path: "/profile/billing/plans",
          type: "redirect",
        },
      ],
    }] : []),
    ...(canEditMembers ? [
      {
        path : "/profile/team-management",
        Component : ProfileTeamManagement,
        exact : true,
      }
    ] : []),
    ...(canEditFeatureFlags ? [
      {
        path : "/profile/feature-flags",
        Component : ProfileFeatureFlags,
        exact : true,
      }
    ] : []),
    { path: "/profile/account-details", type: "redirect", },
  ]
};

const getMenuItems = (user) => {
  const canEditBilling = permissionService.can(user, PermissionEnum.editOrganizationBilling);
  const canEditMembers = permissionService.can(user, PermissionEnum.editOrganizationMembers);
  const canEditIntegrations = permissionService.can(user, PermissionEnum.editOrganizationIntegrations);
  const canEditFeatureFlags = permissionService.can(user, PermissionEnum.editFeatureFlags);

  return [
    { path: "/profile/account-details", label: "Account Details", },
    { path: "/profile/interview-process", label: "Interview Process", },
    ...(canEditBilling ? [{ path: "/profile/billing", label: "Billing", }] : []),
    ...(canEditMembers ? [{ path: "/profile/team-management", label: "Team Management", }] : []),
    ...(canEditIntegrations ? [{ path: "/profile/integrations", label: "Integrations" }] : []),
    ...(canEditFeatureFlags ? [{ path: "/profile/feature-flags", label: "Feature Flags" }] : [])
  ];
}

const Profile = () => {
  useEffect(() => {
    return injectStyleOverride();
  }, []);
  const authController = useAuthController()
  return (
    <Layout title="Your Account" menuItems={getMenuItems(authController.user)}>
      <RouteSwitch routes={getRoutes(authController.user)} />
    </Layout>
  );
};

export default Profile;
