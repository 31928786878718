import * as api from "../api/apiService";

export enum UserSettingName {
  advancedSearch = "advancedSearch",
  showSearchBar = "showSearchBar",
  luceneSearch = "lucene_search",
}

export type UserSettings = Record<UserSettingName, boolean>;

class UserSettingsService {

  async fetchSettings(): Promise<UserSettings> {
    const res = await api.GetUserSettings();
    // because we store boolean as string in the backend, we need to convert the string to boolean
    return this.convertToBoolean(res.data);
  }

  async updateSettings(obj: Partial<UserSettings>): Promise<void> {
    const featureFlags = await this.fetchSettings();
    // because we store boolean as string in the backend, we need to convert the boolean to string
    const data = this.convertToString({ ...featureFlags, ...obj });
    return api.CreateUserSettings(data);
  }

  convertToBoolean(data: Record<string, string>): UserSettings {
    return Object.entries(data).reduce((acc, [key, value]) => {
      acc[key as UserSettingName] = value === 'true';
      return acc;
    }, {} as UserSettings);
  }

  convertToString(data: UserSettings): Record<string, string> {
    return Object.entries(data).reduce((acc, [key, value]) => {
      acc[key] = value.toString();
      return acc;
    }, {} as Record<string, string>);
  }
}

export const userSettingsService = new UserSettingsService();