import cn from "classnames";
import * as React from "react";
import { Button } from "../../../../components/DiscoverNew/UI/Button/Button";
import { Icon } from "../../../../components/DiscoverNew/UI/IconComponent/Icon";
import { Loading } from "../../../../components/DiscoverNew/UI/Loading/Loading";

import css from "./ProfileIntegrationsCard.module.css";

type Props = {
  data : {
    title: string
    description: string
    icon: string
  }
  onEnable: () => void
  onDisable: () => void
  isEnabled: boolean
  isLoading?: boolean
  isInactive?: boolean
}

export const ProfileIntegrationsCard = (props: Props) => {
  const { data, isLoading, isEnabled, onEnable, onDisable } = props;

  return (
    <div className={cn(css.holder, props.isInactive && css.inactive, props.isEnabled && css.enabled)}>
      {isLoading && <div className={css.loaderHolder}><Loading /></div>}
      <div className={css.controls}>
        {isEnabled ? (
            <Button className={css.button} onClick={onDisable} theme="outline">Disable</Button>
        ) : (
            <Button className={css.button} onClick={onEnable}>Enable</Button>
        )}
      </div>
      <div className={css.logo}>
        <Icon name={data.icon} />
      </div>
      <div className={css.title}>{data.title}</div>
      <p className={css.description}>{data.description}</p>
    </div>
  )
}