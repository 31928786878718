import * as React from "react";
import { WithConfirmProps } from "../../../components/hoc/withConfirm";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import { useUserSettings } from "../../../hooks/useUserSettings";
import { UserSettingName } from "../../../services/UserSettingsService";
import billingCSS from "../ProfileBilling/ProfileBilling.module.css";
import { ProfileIntegrationsCard } from "../ProfileIntegrations/ProfileIntegrationsCard/ProfileIntegrationsCard";
import css from "./ProfileFeatureFlags.module.css";

type Props = WithConfirmProps & {}

export const ProfileFeatureFlags = (props: Props) => {
  const [userSettings, onChange, loading] = useUserSettings();

  return (
    <div>
      <div className={billingCSS.head}>
        <SectionTitle className={billingCSS.title} level={2}>
          Feature Flags
        </SectionTitle>
      </div>
      <div className={css.content}>
        <ProfileIntegrationsCard
          data={{
            title : "Show Advanced Search",
            description : "Allows to search for candidates using advanced filters modal",
            icon : "search",
          }}
          onEnable={() => onChange({ [UserSettingName.advancedSearch] : true })}
          onDisable={() => onChange({ [UserSettingName.advancedSearch] : false })}
          isLoading={loading}
          isEnabled={userSettings[UserSettingName.advancedSearch]}
        />
        <ProfileIntegrationsCard
          data={{
            title : "Show Search Bar",
            description : "Allows to search for candidates using full-text search",
            icon : "search",
          }}
          onEnable={() => onChange({ [UserSettingName.showSearchBar] : true })}
          onDisable={() => onChange({ [UserSettingName.showSearchBar] : false })}
          isLoading={loading}
          isEnabled={userSettings[UserSettingName.showSearchBar]}
        />
        <ProfileIntegrationsCard
          data={{
            title : "Use Lucene Search",
            description : "Uses Lucene Query syntax for the Search Bar instead of full-text search",
            icon : "search",
          }}
          isInactive={!userSettings[UserSettingName.showSearchBar]}
          onEnable={() => onChange({ [UserSettingName.luceneSearch] : true })}
          onDisable={() => onChange({ [UserSettingName.luceneSearch] : false })}
          isLoading={loading}
          isEnabled={userSettings[UserSettingName.luceneSearch]}
        />
      </div>
    </div>
  );
};