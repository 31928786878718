import * as React from "react";
import { userService } from "../../projects/UserService";
import { injectStyleOverride } from "../../../components/DiscoverNew/DiscoverNewWrapper";
import { Formatter } from "../../../components/DiscoverNew/util/Formatter";
import { Loading } from "../../../components/DiscoverNew/UI/Loading/Loading";
import AdminUserModal from "../../../components/modals/AdminUserModal";
import { BackButton } from "../../../components/ui/BackButton/BackButton";
import { InfoTable } from "../../../components/ui/InfoTable/InfoTable";
import { useNotifications } from "../../../components/DiscoverNew/contexts/Notification/notifications";
import { withConfirm } from "../../../components/hoc/withConfirm";
import { useHistory } from "react-router-dom";
import { ContextMenuDropdown } from "../../../components/DiscoverNew/UI/ContextMenuDropdown/ContextMenuDropdown";

import css from "./AdminUser.module.css";
import { Dropdown } from "../../../components/DiscoverNew/UI/Dropdown/Dropdown";
import { authService } from "../../../components/DiscoverNew/services/AuthService";
import { useAuthController } from "../../../contexts/AuthProvider";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import Divider from "../../../components/ui/Divider/Divider";
import { Link } from "../../../components/DiscoverNew/UI/Link/Link";
import {
  ProfileIntegrationsCard
} from "../../profile/ProfileIntegrations/ProfileIntegrationsCard/ProfileIntegrationsCard";
import { UserSettingName } from "../../../services/UserSettingsService";
import { adminService } from "../../../services/AdminService";

export class AdminUser extends React.Component {
  cleanStyleOverride;

  constructor(props) {
    super(props);
    this.state = {
      initializing : true,
      activating : false,
      user : undefined,
      userSettings: {},
      isEditUserModalOpen : false,
    };
  }

  async componentDidMount() {
    this.cleanStyleOverride = injectStyleOverride();
    try {
      await this.fetchUser();
      this.setState({ initializing : false });
    } catch (err) {
      this.setState({ initializing : false });
      console.error(`couldn't fetch the user`, err);
    }
  }

  componentWillUnmount() {
    this.cleanStyleOverride();
  }

  fetchUser = async () => {
    const user = await userService.fetchOne(this.props.match.params.userId);
    const userSettings = await adminService.fetchUserSettings(this.props.match.params.userId);
    this.setState({ user, userSettings });
  };

  onEditUser = () => this.setState({ isEditUserModalOpen : true });
  onCloseEditUser = () => this.setState({ isEditUserModalOpen : false });
  onUserUpdated = () => {
    this.onCloseEditUser();
    this.fetchUser();
  };

  onChangeSettings = async (settings) => {
    const userSettingsCopy = { ...this.state.userSettings, ...settings };
    this.setState({ userSettingsLoading : true, userSettings: { ...this.state.userSettings, ...settings } });
    try {
      await adminService.updateUserSettings(this.state.user.id, settings);
      this.setState({ userSettingsLoading : false });
      this.fetchUser();
    } catch (err) {
      console.error("Couldn't update user settings", err);
      this.setState({ userSettingsLoading : false, userSettings: userSettingsCopy });
    }
  }


  onLogin = async (close) => {
    close();
    const res = await userService.loginAs(this.state.user.id);
    authService.pauseSessionAndLoginAs(res, this.props.currentUser);
  }

  onActivate = async () => {
    const { confirm, notifications } = this.props;
    if (this.state.activating) {
      return;
    }

    const isConfirmed = await confirm.open({
      content : <>Are you sure you want to activate {Formatter.fullName(this.state.user)}?</>,
      confirmButtonTitle : "Activate",
    });
    if (!isConfirmed) {
      return;
    }

    try {
      this.setState({ activating : true });
      await userService.activate(this.state.user.id);
      this.setState({ activating : false });
      this.fetchUser();
      notifications.showSuccess(`User has been activated!`);
    } catch (err) {
      this.setState({ activating : false });
      console.error("Couldn't activate the user", err);
      notifications.showError(`Couldn't activate the user!`);
    }
  };

  render() {
    const { initializing, user, isEditUserModalOpen, activating, userSettings, userSettingsLoading } = this.state;

    if (initializing) {
      return (
        <div className="app">
          <div className="max-width">
            <Loading />
          </div>
        </div>
      );
    }

    return (
      <div className="app">
        <div className="max-width">
          <BackButton to="/admin/users" />
          <div style={{ display : "flex", justifyContent : "space-between", marginBottom : 10 }}>
            <h1 className={css.title}>{Formatter.fullName(user)}</h1>
            <ContextMenuDropdown
              contentStyle={{ width : "unset" }}
              label={<ContextMenuDropdown.ActionButton />}
            >
              <ContextMenuDropdown.Item icon="pencil-2" onClick={this.onEditUser}>
                Edit Profile
              </ContextMenuDropdown.Item>
              <Dropdown.Divider />
              <ContextMenuDropdown.Item icon="person" onClick={this.onLogin}>
                Log In to Account
              </ContextMenuDropdown.Item>
            </ContextMenuDropdown>
          </div>
          <div style={{ display : "flex", alignItems : "flex-start" }}>
            <InfoTable>
              <tr>
                <td>Email:</td>
                <td>{user.email}</td>
              </tr>
              <tr>
                <td>Phone number:</td>
                <td>{user.phoneNumber}</td>
              </tr>
              <tr>
                <td>Activated:</td>
                <td>
                  {user.activatedAt ? (
                    Formatter.fullDate(user.activatedAt)
                  ) : (
                    <>No {<Link onClick={this.onActivate}>{activating ? "(activating...)" : "(activate)"}</Link>}</>
                  )}
                </td>
              </tr>
              <tr>
                <td>Created:</td>
                <td>{Formatter.fullDateTime(user.createdAt)}</td>
              </tr>
            </InfoTable>
          </div>
          <Divider />
          <SectionTitle level={2} style={{ marginBottom : 10 }}>
            Organization Info
          </SectionTitle>
          {user.organization ? (
            <InfoTable>
              <tr>
                <td>Name:</td>
                <td><Link style={{ fontSize : 'inherit' }}
                          to={`/admin/organizations/${user.organization.id}`}>{user.organization.name}</Link></td>
              </tr>
            </InfoTable>
          ) : (
            <p>No organization</p>
          )}
          {isEditUserModalOpen && (
            <AdminUserModal onClose={this.onCloseEditUser} onSuccess={this.onUserUpdated} userId={user.id} />
          )}
          <Divider />
          <SectionTitle level={2} style={{ marginBottom : 10 }}>
            Feature Flags
          </SectionTitle>
          <div className={css.content}>
            <ProfileIntegrationsCard
              data={{
                title : "Show Advanced Search",
                description : "Allows to search for candidates using advanced filters modal",
                icon : "search",
              }}
              onEnable={() => this.onChangeSettings({ [UserSettingName.advancedSearch] : true })}
              onDisable={() => this.onChangeSettings({ [UserSettingName.advancedSearch] : false })}
              isLoading={userSettingsLoading}
              isEnabled={userSettings[UserSettingName.advancedSearch]}
            />
            <ProfileIntegrationsCard
              data={{
                title : "Show Search Bar",
                description : "Allows to search for candidates using full-text search",
                icon : "search",
              }}
              onEnable={() => this.onChangeSettings({ [UserSettingName.showSearchBar] : true })}
              onDisable={() => this.onChangeSettings({ [UserSettingName.showSearchBar] : false })}
              isLoading={userSettingsLoading}
              isEnabled={userSettings[UserSettingName.showSearchBar]}
            />
            <ProfileIntegrationsCard
              data={{
                title : "Use Lucene Search",
                description : "Uses Lucene Query syntax for the Search Bar instead of full-text search",
                icon : "search",
              }}
              isInactive={!userSettings[UserSettingName.showSearchBar]}
              onEnable={() => this.onChangeSettings({ [UserSettingName.luceneSearch] : true })}
              onDisable={() => this.onChangeSettings({ [UserSettingName.luceneSearch] : false })}
              isLoading={userSettingsLoading}
              isEnabled={userSettings[UserSettingName.luceneSearch]}
            />
          </div>
        </div>
      </div>
    );
  }
}

AdminUser = (function withUserReducer(WrappedComponent) {
  return function(props) {
    const notifications = useNotifications();
    const history = useHistory();
    const { user } = useAuthController();
    return <WrappedComponent {...props} history={history} notifications={notifications} currentUser={user} />;
  };
})(AdminUser);

AdminUser = withConfirm(AdminUser);
