import { css as emotionCSS } from "emotion";
import React from "react";
import { Modal } from "react-bootstrap";
import modal from "../../../../modals/modal.module.css";
import { Input } from "../../../Form/Input/Input";
import { Select, SelectOption } from "../../../Form/Select/Select";
import { Button } from "../../../UI/Button/Button";
import { Icon } from "../../../UI/IconComponent/Icon";

import css from "./ModalAdvancedFilters.module.css";

type FilterData = {
  field: string
  operator: string
  value: string
}

const operatorOptions: SelectOption[] = [
  { value : "INCLUDES", label : "INCLUDES" },
  { value : "NOT_INCLUDES", label : "NOT INCLUDES" },
];

const fieldOptions: SelectOption[] = [
  { value : "certifications.name", label : "Certification" },
  { value : "certifications.organization", label : "Certification Organization" },
]

function getDefaultValue() {
  return [
    [
      { field : "", operator : "INCLUDES", value : "" }
    ]
  ];
}

//const data: FilterData[][] = [
//  [
//    { field : "industry", operator : "INCLUDES", value : "Marketing" },
//  ],
//  [
//    { field : "position", operator : "INCLUDES", value : "Frontend Developer" },
//    { field : "position", operator : "INCLUDES", value : "Frontend Engineer" },
//  ],
//  [
//    { field : "position", operator : "NOT INCLUDES", value : "Junior" },
//  ],
//];

type Props = {
  onClose: () => void;
  onApply: (data: any) => void
  defaultValue: any
}

export const ModalAdvancedFilters = (props: Props) => {
  const [filters, setFilters] = React.useState<FilterData[][]>(props.defaultValue || getDefaultValue());

  const addFilter = (index?: number) => {
    let copy: FilterData[][] = JSON.parse(JSON.stringify(filters));
    if (index !== undefined) {
      copy[index] = [
        ...copy[index],
        { field : "", operator : "INCLUDES", value : "" }
      ]
    } else {
      copy = [
        ...copy,
        [{ field : "", operator : "INCLUDES", value : "" }]
      ]
    }
    setFilters(copy);
  };

  const deleteFilter = (indexes: number[]) => {
    let copy: FilterData[][] = JSON.parse(JSON.stringify(filters));
    if (copy[indexes[0]].length > 1) {
      copy[indexes[0]] = [
        ...copy[indexes[0]].slice(0, indexes[1]),
        ...copy[indexes[0]].slice(indexes[1] + 1)
      ]
    } else {
      copy = [
        ...copy.slice(0, indexes[0]),
        ...copy.slice(indexes[0] + 1),
      ]
    }
    setFilters(copy);
  };

  const updateFilter = (indexes: number[], data: Partial<FilterData>) => {
    const copy: FilterData[][] = JSON.parse(JSON.stringify(filters));
    copy[indexes[0]][indexes[1]] = {
      ...filters[indexes[0]][indexes[1]],
      ...data,
    };
    setFilters(copy);
  };

  return (
    <Modal show={true} onHide={() => null} centered className={emotionCSS`
        .modal-dialog {
          max-width: 1000px
        }
        .modal-content > div {
          min-height: initial;
        }
        .modal-content {
          min-height: initial;
        }
      `}>
      <Icon name="fa-xmark" className={modal.close} onClick={props.onClose} />
      <div className={modal.body}>
        <h3 className={modal.title} style={{ marginBottom : 20 }}>Advanced Filters</h3>
        {filters.map(((item, i) => {
          return (
            <React.Fragment key={i}>
              <div className={css.group}>
                {item.map((innerItem, j) => {
                  return (
                    <div className={css.filter} key={i + '' + j}>
                      <div className={css.inputs}>
                        <Select value={fieldOptions.find(item => item.value === innerItem.field)} options={fieldOptions}
                                placeholder="Select a filter"
                                onChange={v => updateFilter([i, j], { field : (v as SelectOption).value as string })} />
                        <Select value={operatorOptions.find(item => item.value === innerItem.operator)} options={operatorOptions}
                                onChange={v => updateFilter([i, j], { operator : (v as SelectOption).value as string })} />
                        <Input value={innerItem.value}
                               placeholder="Enter value here"
                               onChange={e => updateFilter([i, j], { value : e.target.value })} />
                      </div>
                      <button disabled={j !== item.length - 1} onClick={() => addFilter(i)}
                              className={css.connectorButton}>OR
                      </button>
                      <button disabled={filters.length === 1 && item.length === 1} className={css.deleteButton} onClick={() => deleteFilter([i, j])}>
                        <Icon name="fa-trash" />
                      </button>
                    </div>
                  );
                })}
              </div>
              <div style={{ display : "table" }}>
                <div className={css.connectorHolder}>
                  <div className={css.connectorHolderConnector} />
                  <button className={css.connectorButton} onClick={() => addFilter()}>AND</button>
                  {!!filters[i + 1] && <div className={css.connectorHolderConnector} />}
                </div>
              </div>
            </React.Fragment>
          );
        }))}
        <footer style={{ paddingTop : 30, display : "flex", gap: 12, justifyContent : "flex-end" }}>
          <Button theme='outline' onClick={() => props.onApply(getDefaultValue())} style={{ minWidth : 140 }}>Reset</Button>
          <Button onClick={() => props.onApply(filters)} style={{ minWidth : 140 }}>Apply Filters</Button>
        </footer>
      </div>
    </Modal>
  );
};